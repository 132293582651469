import React from "react";
import { Divider } from "@material-ui/core";
import { graphql, Link } from "gatsby";
import { RichText, Date } from "prismic-reactjs";
import styled from "@emotion/styled";
// import CommonLayout from "../components/layout/CommonLayout";
import Layout from "../components/layout/index";
import SEO from "../components/seo";

const CardWrapper = styled.div`
  background-color: #fff;
  box-shadow: rgb(17 17 26 / 5%) 0px 1px 0px, rgb(17 17 26 / 10%) 0px 0px 8px;
  border-radius: 0.5em;
  max-width: 600px;
  width: 95%;
  margin: 0 auto;
  font-family: "Nunito Sans";
`;
const StyledLink = styled(Link)`
  font-size: clamp(1.3rem, 2.5vw, 1.4rem);
  color: #000;
  font-style: "Nunito Sans";
  font-weight: 600;
  text-decoration: none;
  :hover {
    color: #fbb400;
    text-decoration: underline;
  }
`;

const ContainerTutorial = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(25rem, 1fr));
  gap: 1.2em;
`;

const CategoryApp = (props) => {
  const {
    data: { posts },
    pageContext: { name },
  } = props;
  return (
    <Layout>
      <SEO title={`${name} (app)`} />
      <div
        style={{
          maxWidth: 900,
          height: "100vh",
          margin: "0 auto",
        }}
      >
        <>
          <div
            style={{
              padding: "0.6em 0.4em",
              fontSize: "2rem",
              fontWeight: 600,
              borderRadius: 4,
              textTransform: "uppercase",
            }}
          >
            <strong style={{ color: "#fbb400", marginRight: "0.4em" }}>
              App
            </strong>
            {props.pageContext.name.replace("-", " ")}
          </div>
        </>
        <ContainerTutorial>
          {posts.nodes.length !== 0 &&
            posts.nodes.map((object) => (
              <CardWrapper key={object.id}>
                <div
                  style={{
                    padding: "1.5em",
                    width: "100%",
                    height: "100%",
                    display: "grid",
                    gap: "0.5em",
                  }}
                >
                  <StyledLink to={`/mobile/${object.uid}`}>
                    {RichText.asText(object.data.title.raw).length !== 0 &&
                      RichText.asText(object.data.title.raw)}
                  </StyledLink>

                  <div
                    style={{
                      fontSize: "clamp(0.9rem, 2.5vw, 1rem)",
                      color: "rgb(64 ,64 ,64)",
                    }}
                  >
                    {RichText.asText(object.data.description.raw).length !==
                      0 && RichText.asText(object.data.description.raw)}
                  </div>

                  <div
                    style={{
                      color: "rgba(117, 117, 117, 1)",
                      padding: "0.3em 0em",
                      fontSize: "0.85rem",
                    }}
                  >
                    <time>
                      {new Intl.DateTimeFormat("en-US", {
                        month: "short",
                        day: "2-digit",
                        year: "numeric",
                      }).format(Date(object.data.date))}
                    </time>
                  </div>
                </div>
              </CardWrapper>
            ))}
        </ContainerTutorial>
        <Divider
          variant="middle"
          style={{
            marginBottom: "1em",
            marginTop: "1.5em",
          }}
        />
      </div>
    </Layout>
  );
};

export default CategoryApp;

export const query = graphql`
  query ($language: String!, $slug: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    posts: allPrismicPostApp(
      sort: { fields: [last_publication_date], order: DESC }
      filter: {
        data: {
          categories: {
            elemMatch: {
              category: { lang: { eq: $language }, slug: { eq: $slug } }
            }
          }
        }
      }
    ) {
      nodes {
        id
        uid
        url
        lang
        data {
          title {
            raw
          }
          description {
            raw
          }
          date
          categories {
            category {
              id
              document {
                ... on PrismicCategory {
                  data {
                    name
                  }
                }
              }
            }
          }
          body {
            ... on PrismicPostAppBodyBannerWithCaption {
              id
              slice_label
              slice_type
              primary {
                image_banner {
                  alt
                  url
                  dimensions {
                    height
                    width
                  }
                }
                description {
                  raw
                }
              }
            }
          }
        }
      }
    }
  }
`;
